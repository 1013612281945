<template>
    <transition appear @enter="onEnter">
        <svg
            id="lemon"
            ref="lemon"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            :width="size"
            :height="size"
            version="1.1"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 220.13 189.75"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
            class="hero"
        >
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="_2586340664544">
                    <path
                        ref="heroBody"
                        class="hero_body"
                        fill="#F5BC5B"
                        fill-rule="nonzero"
                        d="M179.71 44.96c-6.9,-13.39 -16.11,-22.67 -21.28,-24.62l0 0c-0.07,-0.02 -6.86,-2.27 -11.09,-8.8 -0.27,-0.43 -0.53,-0.82 -0.78,-1.21 -3.65,-5.68 -4.52,-7.03 -11.46,-9.63 -8,-2.99 -15.44,4.41 -17.53,6.74 -2.9,3.25 -7.75,3.83 -7.79,3.84 -22.05,1.86 -34.81,6.96 -44.08,17.58 -10.05,11.55 -15.9,29.73 -22.09,55.33 -2.99,12.39 -2.27,23.99 2.15,34.47 3.6,8.55 9.65,16.37 17.98,23.29 16.25,13.5 34.83,18.48 35.01,18.52 3.15,0.8 4.68,2.68 6.16,4.51 1.71,2.11 3.32,4.09 7.18,4.21 0.29,0 0.56,0.03 0.81,0.06 0.53,0.06 1,0.12 1.58,0 1.31,-0.27 3.13,-1.44 7.16,-5.4 1.73,-1.71 5.83,-2.64 11.02,-3.81 8.71,-1.98 20.65,-4.69 31.45,-12.95 5.91,-4.51 10.87,-10.28 14.75,-17.14 4.52,-8 7.74,-17.74 9.55,-28.99 3.96,-24.62 -2.55,-44.06 -8.7,-56z"
                    />
                    <g ref="freckles">
                        <g ref="frecklesLeft">
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M74.79 77.48c-0.08,0.02 -0.18,-0.01 -0.2,-0.12 -0.03,-0.11 0.05,-0.18 0.13,-0.2 0.08,-0.01 0.18,0.02 0.21,0.13 0.02,0.11 -0.06,0.17 -0.14,0.19z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M75.02 78.54c-0.01,0.01 -0.03,0.01 -0.05,0 -0.64,0.12 -1.3,-0.31 -1.43,-0.96 -0.13,-0.65 0.3,-1.32 0.96,-1.46 0.62,-0.13 1.18,0.21 1.41,0.76 0.03,0.04 0.05,0.11 0.06,0.19l0 0c0.02,0.08 0.03,0.14 0.02,0.21 0,0.58 -0.36,1.13 -0.97,1.26zm-0.3 -1.38c-0.08,0.02 -0.16,0.09 -0.13,0.2 0.02,0.11 0.12,0.14 0.2,0.12 0.08,-0.02 0.16,-0.08 0.14,-0.19 -0.03,-0.11 -0.13,-0.14 -0.21,-0.13z"
                            />
                            <path
                                class="a"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M77.79 71.98c-0.09,0.01 -0.18,-0.02 -0.2,-0.13 -0.03,-0.11 0.04,-0.19 0.12,-0.21 0.09,-0.01 0.19,0.03 0.22,0.14 0.02,0.11 -0.06,0.18 -0.14,0.2z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M78.02 73.02c-0.02,0.01 -0.03,0.01 -0.05,0.01 -0.64,0.12 -1.3,-0.32 -1.43,-0.96 -0.13,-0.66 0.3,-1.33 0.96,-1.47 0.62,-0.13 1.17,0.21 1.4,0.76 0.04,0.06 0.06,0.12 0.07,0.2l0 0c0.02,0.07 0.02,0.13 0.02,0.2 0,0.59 -0.36,1.13 -0.97,1.26zm-0.31 -1.38c-0.08,0.02 -0.15,0.1 -0.12,0.21 0.02,0.11 0.11,0.14 0.2,0.13 0.08,-0.02 0.16,-0.09 0.14,-0.2 -0.03,-0.11 -0.13,-0.15 -0.22,-0.14z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M70.93 70.54c-0.06,0.01 -0.13,-0.03 -0.15,-0.11 -0.02,-0.07 0.04,-0.14 0.1,-0.15 0.06,-0.01 0.13,0.02 0.16,0.1 0.01,0.08 -0.04,0.14 -0.11,0.16z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M71.17 71.58c-0.01,0 -0.03,0.01 -0.05,0.01 -0.62,0.1 -1.25,-0.3 -1.38,-0.94 -0.13,-0.63 0.28,-1.28 0.92,-1.42 0.59,-0.12 1.12,0.21 1.35,0.73 0.03,0.06 0.06,0.12 0.07,0.2l0 0c0.02,0.08 0.02,0.15 0.02,0.21 -0.01,0.55 -0.35,1.09 -0.93,1.21zm-0.29 -1.3c-0.06,0.01 -0.12,0.08 -0.1,0.15 0.02,0.08 0.09,0.12 0.15,0.11 0.07,-0.02 0.12,-0.08 0.11,-0.16 -0.03,-0.08 -0.1,-0.11 -0.16,-0.1z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M74.38 63.93c-0.09,0.01 -0.18,-0.02 -0.2,-0.13 -0.03,-0.11 0.04,-0.19 0.12,-0.21 0.09,-0.01 0.19,0.03 0.22,0.14 0.01,0.11 -0.06,0.18 -0.14,0.2z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M74.61 64.97c-0.02,0.01 -0.03,0.01 -0.05,0.01 -0.64,0.12 -1.3,-0.3 -1.43,-0.96 -0.13,-0.67 0.3,-1.34 0.96,-1.47 0.61,-0.13 1.17,0.23 1.4,0.76 0.04,0.06 0.06,0.12 0.07,0.2l0 0c0.02,0.08 0.03,0.15 0.01,0.2 0.01,0.59 -0.36,1.13 -0.96,1.26zm-0.31 -1.38c-0.08,0.02 -0.15,0.1 -0.12,0.21 0.02,0.11 0.11,0.14 0.2,0.13 0.08,-0.02 0.15,-0.09 0.14,-0.2 -0.03,-0.11 -0.13,-0.15 -0.22,-0.14z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M88.85 63.78c-0.11,0.03 -0.23,-0.03 -0.25,-0.16 -0.03,-0.14 0.05,-0.24 0.16,-0.27 0.1,-0.02 0.23,0.04 0.26,0.18 0.02,0.13 -0.07,0.23 -0.17,0.25z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M89.08 64.83c-0.02,0 -0.03,0 -0.05,0.01 -0.67,0.11 -1.34,-0.33 -1.48,-1 -0.14,-0.68 0.31,-1.39 0.99,-1.53 0.64,-0.14 1.22,0.24 1.46,0.8 0.03,0.06 0.05,0.12 0.07,0.2l0 0c0.01,0.06 0.02,0.13 0.01,0.2 0.01,0.61 -0.37,1.18 -1,1.32zm-0.32 -1.48c-0.11,0.03 -0.19,0.13 -0.16,0.27 0.02,0.13 0.14,0.19 0.25,0.16 0.1,-0.02 0.19,-0.12 0.17,-0.25 -0.03,-0.14 -0.16,-0.2 -0.26,-0.18z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M90.72 73.96c-0.06,0.02 -0.13,-0.02 -0.15,-0.1 -0.02,-0.08 0.03,-0.14 0.1,-0.15 0.06,-0.01 0.13,0.02 0.15,0.1 0.02,0.08 -0.04,0.14 -0.1,0.15z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M90.96 75.01c-0.02,0 -0.03,0 -0.05,-0.01 -0.62,0.12 -1.26,-0.28 -1.38,-0.92 -0.13,-0.63 0.28,-1.28 0.92,-1.42 0.59,-0.12 1.12,0.21 1.35,0.73 0.03,0.06 0.06,0.12 0.07,0.2l0 0c0.02,0.08 0.02,0.14 0.02,0.21 -0.01,0.57 -0.35,1.09 -0.93,1.21zm-0.29 -1.3c-0.07,0.01 -0.12,0.07 -0.1,0.15 0.02,0.08 0.09,0.12 0.15,0.1 0.06,-0.01 0.12,-0.07 0.1,-0.15 -0.02,-0.08 -0.09,-0.11 -0.15,-0.1z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M83.26 88.79c-0.23,0.05 -0.49,-0.04 -0.55,-0.34 -0.07,-0.32 0.13,-0.51 0.36,-0.56 0.23,-0.05 0.5,0.04 0.58,0.36 0.05,0.3 -0.16,0.49 -0.39,0.54z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M83.5 89.85c-0.02,0.01 -0.04,0.01 -0.06,0.02 -0.81,0.13 -1.62,-0.41 -1.78,-1.2 -0.17,-0.83 0.37,-1.67 1.2,-1.85 0.79,-0.16 1.51,0.32 1.78,1.02 0.02,0.06 0.04,0.11 0.06,0.19l0 0c0.01,0.06 0.01,0.11 0.01,0.18 0.04,0.75 -0.42,1.48 -1.21,1.64zm-0.43 -1.96c-0.23,0.05 -0.43,0.24 -0.36,0.56 0.06,0.3 0.32,0.39 0.55,0.34 0.23,-0.05 0.44,-0.24 0.39,-0.54 -0.08,-0.32 -0.35,-0.41 -0.58,-0.36z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M72.59 86.91l-0.01 0 0.01 0 0 0zm-0.07 -0.02l0 0 0 -0.01 0 0c0,0 0,0.01 0,0.01zm-0.01 0l0.01 -0.01c0,0 0,0 0,0l0 0 -0.01 0.01z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M72.79 87.86c-0.01,0.01 -0.03,0.01 -0.04,0.01 -0.53,0.08 -1.08,-0.25 -1.18,-0.79 -0.11,-0.55 0.24,-1.1 0.78,-1.21 0.51,-0.11 0.98,0.19 1.16,0.65 0.03,0.04 0.05,0.09 0.05,0.14 0.02,0.04 0.02,0.09 0.01,0.14 0.01,0.5 -0.28,0.96 -0.78,1.06zm-0.2 -0.95l-0.01 0 0.01 0 0 0zm-0.07 -0.03l0 0 0 0.01 0 0c0,0 0,-0.01 0,-0.01 0,0 0,0 0,0l-0.01 0.01 0.01 -0.01z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M65.09 79.97c-0.17,0.03 -0.35,-0.04 -0.4,-0.26 -0.05,-0.23 0.09,-0.37 0.26,-0.41 0.17,-0.03 0.37,0.04 0.43,0.26 0.03,0.23 -0.12,0.37 -0.29,0.41z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M65.34 81.03c-0.02,0 -0.04,0.01 -0.06,0.01 -0.74,0.12 -1.48,-0.37 -1.63,-1.11 -0.15,-0.75 0.34,-1.52 1.09,-1.68 0.72,-0.15 1.37,0.28 1.62,0.92 0.03,0.04 0.05,0.11 0.06,0.17l0 0c0.02,0.08 0.03,0.15 0.02,0.2 0.02,0.69 -0.4,1.34 -1.1,1.49zm-0.39 -1.73c-0.17,0.04 -0.31,0.18 -0.26,0.41 0.05,0.22 0.23,0.29 0.4,0.26 0.17,-0.04 0.32,-0.18 0.29,-0.41 -0.06,-0.22 -0.26,-0.29 -0.43,-0.26z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M64.14 65.35c-0.14,0.03 -0.31,-0.03 -0.35,-0.22 -0.04,-0.19 0.09,-0.32 0.23,-0.35 0.15,-0.03 0.32,0.03 0.37,0.22 0.03,0.19 -0.1,0.32 -0.25,0.35z"
                            />
                            <path
                                class="freckles freckles_left"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M64.38 66.41c-0.02,0 -0.03,0.01 -0.05,0 -0.71,0.13 -1.44,-0.35 -1.58,-1.06 -0.15,-0.74 0.32,-1.48 1.06,-1.64 0.69,-0.14 1.32,0.27 1.56,0.88 0.04,0.06 0.05,0.11 0.07,0.19l0 0c0.01,0.06 0.02,0.13 0.01,0.19 0.02,0.66 -0.38,1.3 -1.07,1.44zm-0.36 -1.63c-0.14,0.03 -0.27,0.16 -0.23,0.35 0.04,0.19 0.21,0.25 0.35,0.22 0.15,-0.03 0.28,-0.16 0.25,-0.35 -0.05,-0.19 -0.22,-0.25 -0.37,-0.22z"
                            />
                        </g>
                        <g ref="frecklesRight">
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M160.1 84.44c-0.02,0.08 -0.08,0.16 -0.19,0.14 -0.11,-0.02 -0.15,-0.11 -0.13,-0.19 0.01,-0.09 0.07,-0.17 0.19,-0.15 0.11,0.02 0.14,0.12 0.13,0.2z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M161.17 84.61c0,0.02 0,0.03 -0.02,0.04 -0.13,0.65 -0.77,1.1 -1.42,0.98 -0.66,-0.12 -1.12,-0.76 -1.01,-1.43 0.11,-0.62 0.64,-1.01 1.23,-1.02 0.05,-0.01 0.12,-0.01 0.2,0.01l0 0c0.08,0.01 0.14,0.03 0.2,0.06 0.54,0.21 0.93,0.75 0.82,1.36zm-1.39 -0.22c-0.02,0.08 0.02,0.17 0.13,0.19 0.11,0.02 0.17,-0.06 0.19,-0.14 0.01,-0.08 -0.02,-0.18 -0.13,-0.2 -0.12,-0.02 -0.18,0.06 -0.19,0.15z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M156.08 79.62c-0.01,0.09 -0.07,0.16 -0.18,0.14 -0.12,-0.01 -0.17,-0.11 -0.15,-0.19 0.01,-0.08 0.09,-0.16 0.21,-0.15 0.11,0.03 0.14,0.12 0.12,0.2z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M157.14 79.79c0,0.02 0,0.03 -0.01,0.05 -0.12,0.64 -0.78,1.09 -1.41,0.98 -0.67,-0.12 -1.14,-0.77 -1.02,-1.44 0.1,-0.62 0.63,-1 1.22,-1.02 0.07,-0.01 0.14,-0.01 0.22,0.01l0 0c0.06,0.01 0.12,0.03 0.18,0.06 0.56,0.21 0.93,0.75 0.82,1.36zm-1.39 -0.22c-0.02,0.08 0.03,0.18 0.15,0.19 0.11,0.02 0.17,-0.05 0.18,-0.14 0.02,-0.08 -0.01,-0.17 -0.12,-0.2 -0.12,-0.01 -0.2,0.07 -0.21,0.15z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M152.22 85.46c-0.01,0.07 -0.07,0.12 -0.15,0.11 -0.08,-0.01 -0.12,-0.08 -0.11,-0.15 0.01,-0.06 0.07,-0.12 0.15,-0.11 0.08,0.02 0.12,0.09 0.11,0.15z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M153.28 85.62c0,0.02 -0.01,0.04 -0.01,0.06 -0.14,0.61 -0.74,1.06 -1.38,0.94 -0.64,-0.12 -1.09,-0.74 -0.98,-1.38 0.1,-0.6 0.61,-0.97 1.17,-0.99 0.07,-0.01 0.13,-0.01 0.21,0.01l0 0c0.09,0.01 0.15,0.03 0.21,0.06 0.51,0.21 0.88,0.72 0.78,1.3zm-1.32 -0.2c-0.01,0.07 0.03,0.14 0.11,0.15 0.08,0.01 0.14,-0.04 0.15,-0.11 0.01,-0.06 -0.03,-0.13 -0.11,-0.15 -0.08,-0.01 -0.14,0.05 -0.15,0.11z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M147.34 79.83c-0.01,0.08 -0.07,0.16 -0.18,0.14 -0.12,-0.02 -0.17,-0.11 -0.15,-0.19 0.01,-0.09 0.09,-0.17 0.2,-0.15 0.12,0.02 0.15,0.11 0.13,0.2z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M148.4 80c0,0.01 0,0.03 -0.01,0.04 -0.12,0.65 -0.76,1.1 -1.41,0.98 -0.67,-0.12 -1.14,-0.76 -1.03,-1.43 0.11,-0.62 0.65,-1.01 1.23,-1.03 0.07,0 0.14,0 0.22,0.01l0 0c0.08,0.01 0.14,0.03 0.18,0.06 0.56,0.22 0.93,0.76 0.82,1.37zm-1.39 -0.22c-0.02,0.08 0.03,0.17 0.15,0.19 0.11,0.02 0.17,-0.06 0.18,-0.14 0.02,-0.09 -0.01,-0.18 -0.13,-0.2 -0.11,-0.02 -0.19,0.06 -0.2,0.15z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M152.54 66.32c-0.02,0.11 -0.11,0.2 -0.24,0.18 -0.15,-0.03 -0.21,-0.15 -0.19,-0.25 0.02,-0.11 0.12,-0.2 0.26,-0.18 0.13,0.02 0.19,0.15 0.17,0.25z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M153.6 66.49c0,0.02 -0.01,0.03 -0.01,0.05 -0.15,0.66 -0.8,1.13 -1.47,1.01 -0.69,-0.13 -1.18,-0.8 -1.06,-1.49 0.11,-0.64 0.67,-1.04 1.28,-1.06 0.07,-0.01 0.13,0 0.21,0.01l0 0c0.07,0.01 0.13,0.03 0.19,0.06 0.57,0.22 0.97,0.78 0.86,1.42zm-1.49 -0.24c-0.02,0.1 0.04,0.22 0.19,0.25 0.13,0.02 0.22,-0.07 0.24,-0.18 0.02,-0.1 -0.04,-0.23 -0.17,-0.25 -0.14,-0.02 -0.24,0.07 -0.26,0.18z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M162.7 68.33c-0.01,0.06 -0.07,0.12 -0.15,0.11 -0.08,-0.02 -0.12,-0.09 -0.11,-0.15 0.01,-0.06 0.07,-0.12 0.15,-0.11 0.08,0.02 0.12,0.09 0.11,0.15z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M163.76 68.49c-0.01,0.02 -0.01,0.04 -0.03,0.05 -0.12,0.62 -0.72,1.06 -1.36,0.94 -0.64,-0.11 -1.09,-0.73 -0.98,-1.38 0.1,-0.59 0.61,-0.96 1.17,-0.98 0.07,-0.01 0.13,-0.01 0.21,0.01l0 0c0.08,0.01 0.15,0.03 0.21,0.06 0.52,0.21 0.88,0.72 0.78,1.3zm-1.32 -0.2c-0.01,0.06 0.03,0.13 0.11,0.15 0.08,0.01 0.14,-0.05 0.15,-0.11 0.01,-0.06 -0.03,-0.13 -0.11,-0.15 -0.08,-0.01 -0.14,0.05 -0.15,0.11z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M173.73 80.73c-0.04,0.24 -0.22,0.44 -0.52,0.39 -0.32,-0.06 -0.43,-0.31 -0.39,-0.54 0.04,-0.24 0.23,-0.45 0.55,-0.41 0.3,0.07 0.4,0.33 0.36,0.56z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M174.81 80.9c0,0.02 -0.01,0.04 -0.01,0.06 -0.17,0.8 -0.97,1.35 -1.77,1.21 -0.83,-0.15 -1.42,-0.96 -1.28,-1.79 0.14,-0.8 0.85,-1.29 1.61,-1.28 0.06,0 0.11,0 0.19,0.01l0 0c0.07,0.01 0.11,0.03 0.17,0.05 0.72,0.25 1.22,0.95 1.09,1.74zm-1.99 -0.32c-0.04,0.23 0.07,0.48 0.39,0.54 0.3,0.05 0.48,-0.15 0.52,-0.39 0.04,-0.23 -0.06,-0.49 -0.36,-0.56 -0.32,-0.04 -0.51,0.17 -0.55,0.41z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M168.05 89.96l0 0.01 0 -0.02 0 0.01zm-0.04 0.05l0 0.01 -0.02 -0.01 0 0c0.01,0 0.02,0 0.02,0zm0 0.01l-0.01 -0.01c0,0 -0.01,0 -0.01,0l0 0 0.02 0.01z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M169.01 90.12c0,0.02 0,0.03 0,0.04 -0.13,0.53 -0.64,0.91 -1.18,0.81 -0.54,-0.1 -0.93,-0.63 -0.83,-1.18 0.08,-0.51 0.53,-0.83 1.03,-0.84 0.05,0 0.1,0 0.15,0.01 0.04,0.01 0.09,0.02 0.14,0.04 0.46,0.18 0.78,0.62 0.69,1.12zm-0.96 -0.17l0 0.02 0 -0.01 0 -0.01zm-0.06 0.06l0 0 0.02 0.01 0 -0.01c0,0 -0.01,0 -0.02,0 0,0 0.01,0 0.01,0l0.01 0.01 -0.02 -0.01z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M158.84 94.37c-0.03,0.17 -0.17,0.32 -0.4,0.28 -0.22,-0.04 -0.3,-0.23 -0.27,-0.4 0.03,-0.17 0.17,-0.32 0.39,-0.29 0.23,0.05 0.31,0.24 0.28,0.41z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M159.91 94.53c0,0.02 0,0.04 -0.01,0.06 -0.16,0.73 -0.89,1.24 -1.64,1.11 -0.75,-0.14 -1.28,-0.88 -1.15,-1.64 0.13,-0.72 0.76,-1.16 1.45,-1.16 0.05,-0.01 0.12,-0.01 0.18,0l0 0c0.08,0.01 0.15,0.03 0.19,0.06 0.65,0.23 1.1,0.86 0.98,1.57zm-1.74 -0.28c-0.03,0.17 0.05,0.36 0.27,0.4 0.23,0.04 0.37,-0.11 0.4,-0.28 0.03,-0.17 -0.05,-0.36 -0.28,-0.41 -0.22,-0.03 -0.36,0.12 -0.39,0.29z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M136.71 81.11c-0.04,0.23 -0.24,0.43 -0.55,0.38 -0.3,-0.05 -0.4,-0.3 -0.36,-0.54 0.04,-0.23 0.22,-0.45 0.53,-0.41 0.3,0.07 0.42,0.33 0.38,0.57z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M137.78 81.27c0,0.02 0,0.04 -0.01,0.06 -0.17,0.8 -0.97,1.36 -1.79,1.21 -0.83,-0.14 -1.4,-0.95 -1.25,-1.79 0.13,-0.79 0.83,-1.28 1.6,-1.27 0.05,-0.01 0.12,-0.01 0.18,0.01l0 0c0.06,0 0.13,0.02 0.19,0.05 0.71,0.25 1.22,0.94 1.08,1.73zm-1.98 -0.32c-0.04,0.24 0.06,0.49 0.36,0.54 0.31,0.05 0.51,-0.15 0.55,-0.38 0.04,-0.24 -0.08,-0.5 -0.38,-0.57 -0.31,-0.04 -0.49,0.18 -0.53,0.41z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M144.9 89.87c-0.03,0.14 -0.15,0.27 -0.34,0.24 -0.19,-0.03 -0.26,-0.19 -0.24,-0.34 0.03,-0.15 0.15,-0.29 0.34,-0.26 0.19,0.04 0.26,0.21 0.24,0.36z"
                            />
                            <path
                                class="freckles freckles_right"
                                fill="#D8A73C"
                                fill-rule="nonzero"
                                d="M145.97 90.04c0,0.01 0,0.03 -0.02,0.04 -0.14,0.71 -0.85,1.21 -1.57,1.08 -0.74,-0.13 -1.25,-0.85 -1.13,-1.58 0.12,-0.7 0.74,-1.13 1.4,-1.14 0.06,0 0.11,0 0.19,0.02l0 0c0.07,0 0.13,0.02 0.19,0.05 0.62,0.23 1.06,0.84 0.94,1.53zm-1.65 -0.27c-0.02,0.15 0.05,0.31 0.24,0.34 0.19,0.03 0.31,-0.1 0.34,-0.24 0.02,-0.15 -0.05,-0.32 -0.24,-0.36 -0.19,-0.03 -0.31,0.11 -0.34,0.26z"
                            />
                        </g>
                    </g>
                    <path
                        ref="legRight"
                        class="leg leg_right"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M170.16 169.08c-8.29,0.25 -18.85,1.51 -26.85,5.71 -1.1,0 -2.14,0.05 -3.08,0.17 0.27,-4.55 -0.15,-9.36 -0.11,-13.58 0.03,-3.75 1.23,-10.54 -1.47,-13.55 -0.92,-1.03 -2.95,-0.83 -3.57,0.46 -0.03,0.06 -0.06,0.13 -0.09,0.2 -0.34,0.7 -0.32,1.44 0,2.07 1.45,4.67 0.45,11.23 0.51,16 0.04,3.46 0.26,14.85 0.97,15.17 0.07,0.23 37.15,4.13 40.69,4.43 4.96,0.43 16.28,0.42 16.41,-6.85 0.17,-9.98 -17.19,-10.41 -23.41,-10.23z"
                    />
                    <path
                        ref="legLeft"
                        class="leg leg_left"
                        fill="#283039"
                        fill-rule="nonzero"
                        d="M92.99 148.78c-3.31,9.18 -4.2,19.43 -3.49,29.22 -9.9,-3.64 -20.97,-3.96 -31.32,-2.37 -4.08,0.62 -17.99,2.03 -16.99,9.34 0.75,5.54 8.84,4.83 12.6,4.65 12.08,-0.57 24.53,-2.25 36.13,-5.67 0.55,-0.16 3.5,-1.33 3.6,-2.78 0.76,-10.69 0.17,-20.9 4.02,-31.14 1.06,-2.83 -3.53,-4.1 -4.55,-1.25z"
                    />
                    <g ref="arms">
                        <path
                            ref="armLeft"
                            class="arm arm_left"
                            :class="{ say_hi: animationIs === 'sayHi' }"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M59.37 104.17c-14.41,-2.45 -26.21,-8.07 -37.41,-15.54 -2.48,-1.38 -2.81,-2.54 -2.81,-2.54 -0.48,-5.12 -1.65,-10.7 -4.82,-14.5 -0.64,-0.77 -2.18,-0.17 -2.06,0.85 0.29,2.64 1.25,5.07 1.87,7.63 0.29,1.18 0.51,2.37 0.7,3.56 -2.93,-2.19 -5.86,-4.49 -8.81,-6.89 -1.39,-1.12 -3.07,0.57 -1.95,1.95 2.73,3.39 5.86,6.46 9.26,9.26 -3.92,0.7 -7.83,0.42 -12.14,0.04 -1.23,-0.11 -1.64,1.73 -0.62,2.29 4.87,2.67 10.8,2.48 16.11,0.87 0.34,0.15 0.71,0.24 1.08,0.2 12.01,8.52 26.69,14 40.48,16.88 2.66,0.56 3.76,-3.61 1.12,-4.06z"
                        />
                        <path
                            ref="armRight"
                            class="arm arm_right"
                            fill="#282F39"
                            fill-rule="nonzero"
                            d="M193.13 101.51c-7.97,-3.72 -18.88,-3.01 -25.26,3.39 -1.17,1.17 0.53,3.2 1.94,2.53 8.12,-3.88 16.31,-6.73 24.59,-1.07 3.56,2.44 6.51,6.08 8.04,10.13 1.31,3.43 0.59,7.28 1.93,10.56 0.05,0.14 0.15,0.25 0.24,0.36 -2.63,3.61 -4.68,9.59 -2.69,13.6 0.55,1.11 2.09,0.91 2.34,-0.31 0.42,-2.15 0.13,-4.31 0.73,-6.47 0.43,-1.52 1.1,-2.86 1.92,-4.15 0.48,1.15 1.02,2.29 1.42,3.36 0.86,2.31 1.44,4.85 2.78,6.93 0.51,0.8 2.02,0.44 2.03,-0.54 0.04,-2.45 -0.88,-4.9 -1.63,-7.21 -0.39,-1.21 -0.76,-2.63 -1.26,-3.98 0.95,0.42 1.83,0.98 2.75,1.8 1.87,1.67 3.22,3.76 4.95,5.56 0.96,1 2.52,0.17 2.11,-1.2 -1.34,-4.45 -7.88,-11.8 -13.18,-10.02 -1.03,-4.83 -0.4,-9.03 -3.29,-13.8 -2.47,-4.1 -6.13,-7.44 -10.46,-9.47z"
                        />
                    </g>
                    <g>
                        <path
                            class="fil4"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M91.36 47.74c-0.06,0.57 -0.17,1.15 -0.28,1.72 -0.1,0.47 -0.35,1.06 -0.14,1.52 0.07,0.17 0.26,0.23 0.42,0.13 0.47,-0.29 0.54,-0.99 0.61,-1.49 0.07,-0.64 0.04,-1.22 -0.05,-1.85 -0.04,-0.3 -0.52,-0.37 -0.56,-0.03z"
                        />
                        <path
                            class="fil4"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M95.79 43.08c-0.26,0.62 -0.47,1.27 -0.67,1.92 -0.16,0.56 -0.52,1.27 -0.29,1.84 0.08,0.18 0.31,0.27 0.48,0.15 0.47,-0.37 0.52,-1.05 0.66,-1.6 0.19,-0.7 0.34,-1.4 0.45,-2.11 0.06,-0.37 -0.49,-0.57 -0.63,-0.2z"
                        />
                        <path
                            class="fil4"
                            fill="#F2D196"
                            fill-rule="nonzero"
                            d="M96.52 49.78c-0.28,0.58 -0.59,1.14 -0.9,1.7 -0.28,0.51 -0.65,0.95 -0.64,1.55 0,0.14 0.17,0.24 0.29,0.19 0.59,-0.24 0.84,-0.84 1.11,-1.38 0.29,-0.6 0.5,-1.24 0.67,-1.89 0.08,-0.31 -0.4,-0.45 -0.53,-0.17z"
                        />
                        <g ref="eyes">
                            <path
                                ref="eyeRightWhite"
                                class="eye eye_right white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M145.74 52.67c0,6.3 -5.11,11.41 -11.41,11.41 -6.3,0
                    -11.41,-5.11 -11.41,-11.41 0,-6.3 5.11,-11.41 11.41,-11.41
                    6.3,0 11.41,5.11 11.41,11.41z"
                            />
                            <path
                                ref="eyeLeftWhite"
                                class="eye eye_left white"
                                fill="white"
                                fill-rule="nonzero"
                                d="M111.86 51.97c0,6.3 -5.1,11.41 -11.4,11.41 -6.3,0
                    -11.41,-5.11 -11.41,-11.41 0,-6.3 5.11,-11.41 11.41,-11.41
                    6.3,0 11.4,5.11 11.4,11.41z"
                            />
                            <path
                                ref="eyeRight"
                                class="eye eye_right"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M138.06 54.08c0,3.64 -2.95,6.59 -6.6,6.59 -3.64,0 -6.59,-2.95 -6.59,-6.59 0,-3.64 2.95,-6.6 6.59,-6.6 3.65,0 6.6,2.96 6.6,6.6z"
                            />
                            <path
                                ref="eyeLeft"
                                class="eye eye_left"
                                fill="#283039"
                                fill-rule="nonzero"
                                d="M109.03 54.53c0,3.65 -2.96,6.6 -6.6,6.6 -3.64,0 -6.6,-2.95 -6.6,-6.6 0,-3.64 2.96,-6.59 6.6,-6.59 3.64,0 6.6,2.95 6.6,6.59z"
                            />
                        </g>
                        <path
                            ref="mouth"
                            class="mouth"
                            fill="#283039"
                            fill-rule="nonzero"
                            d="M120.91 89.92c-0.03,0.37 -0.2,0.74 -0.57,0.95 -1.88,1.1 -4.42,0.43 -6.44,-0.76 -0.75,-0.36 -1.41,-0.83 -1.95,-1.43 -0.17,-0.16 -0.33,-0.32 -0.47,-0.47 -0.27,-0.3 -0.39,-0.61 -0.41,-0.91 -0.02,-0.05 -0.05,-0.1 -0.07,-0.15 -0.25,-0.66 0.46,-1.31 1.1,-1.22 0.42,-0.13 0.92,-0.07 1.37,0.29 0.8,0.65 1.61,1.31 2.53,1.78 0.02,0.01 0.06,0.02 0.09,0.04 0.97,0.27 2.01,0.3 3.22,-0.1 0.83,-0.28 1.73,0.46 1.71,1.31 0,0.25 -0.04,0.47 -0.11,0.67z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </transition>
</template>
<script>
import { gsap } from "gsap";
export default {
    name: "Lemon",

    props: {
        size: {
            type: Number,
            required: false,
            default: 90,
        },

        animationIs: {
            type: String,
            required: false,
            default: null,
        },
    },

    methods: {
        onEnter() {
            if (!this.animationIs) return;
            if (this.animationIs === "squeeze") {
                this.squeeze();
                return;
            } else if (this.animationIs === "jump") {
                this.jump();
                return;
            } else if (this.animationIs === "sad") {
                this.sad();
                return;
            }
        },

        squeeze() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
            gsap.set(this.$refs.heroBody, { transformStyle: "preserve-3d" });
            tl.to(this.$refs.eyes, {
                y: -30,
                x: -20,
                scale: 2,
                duration: 0.5,
                ease: "bounce.out",
                z: 30,
            })
                .to(this.$refs.lemon, { rotationY: 45, duration: 0.1 }, 0)

                .to(this.$refs.arms, { scale: 0.8, x: 5, duration: 0 }, 0)

                .to(
                    this.$refs.eyes,
                    { y: 0, x: 0, scale: 1, duration: 0.3 },
                    0.7
                )

                .to(
                    this.$refs.lemon,
                    { rotationY: 0, skewX: 0, scaleX: 1, duration: 0.8 },
                    0.7
                )
                .to(this.$refs.arms, { scale: 1, x: 0, duration: 0.1 }, 0.7);
        },
        jump() {
            var tl = gsap.timeline({ repeat: -1, repeatDelay: 4 });
            gsap.set(this.$refs.legLeft, { transformOrigin: "right top" });
            gsap.set(this.$refs.armLeft, {
                transformOrigin: "right",
                rotate: 0,
            });

            tl.to(
                this.$refs.legRight,
                {
                    rotate: 35,
                    duration: 0.3,
                },
                0
            )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: -25,
                        duration: 0.3,
                    },
                    0
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: -70,
                        x: -10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 70,
                        x: 10,
                        duration: 0.4,
                    },
                    0
                )
                .to(
                    this.$refs.lemon,
                    {
                        y: -30,
                        duration: 0.8,
                    },
                    0
                )
                .to(
                    this.$refs.legLeft,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.legRight,
                    {
                        rotate: 0,
                        duration: 0.3,
                    },
                    0.7
                )
                .to(
                    this.$refs.armLeft,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.armRight,
                    {
                        rotate: 0,
                        x: 0,
                        duration: 0.4,
                    },
                    0.7
                )
                .to(
                    this.$refs.lemon,
                    {
                        y: 0,
                        duration: 0.3,
                    },
                    0.7
                );
        },
        sad() {
            var tl = gsap.timeline({ repeat: -1 });

            gsap.set(this.$refs.mouth, {
                rotate: "180deg",
                translateX: 10,
                translateY: 10,
            });

            gsap.set(this.$refs.armLeft, {
                rotate: "300deg",
                translateY: 80,
                translateX: -10,
            });
        },
    },
};
</script>

<style scoped>
* {
    overflow: visible;
}

.say_hi {
    transform-origin: bottom right;
    transform: rotate(20deg) translateX(-40px) translateY(30px);
    animation: hi infinite 5s ease-in-out;
}

@keyframes hi {
    0% {
        transform: rotate(20deg) translateX(-40px) translateY(30px);
    }

    5% {
        transform: rotate(60deg) translateX(-40px) translateY(160px);
    }

    10% {
        transform: rotate(20deg) translateX(-40px) translateY(30px);
    }

    90% {
        transform: rotate(20deg) translateX(-40px) translateY(30px);
    }

    95% {
        transform: rotate(60deg) translateX(-40px) translateY(160px);
    }

    100% {
        transform: rotate(20deg) translateX(-40px) translateY(30px);
    }
}
</style>
